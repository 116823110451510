export const COLORS: any = {
    Safe: "bg-risk-bg-green text-risk-green",
    Low: "bg-risk-bg-green text-risk-green",
    Moderate: "bg-risk-bg-yellow text-risk-yellow",
    Caution: "bg-risk-bg-orange text-risk-orange",
    High: "bg-risk-bg-orange text-risk-orange",
    Danger: "bg-risk-bg-red text-risk-red",
    // Very_high: "bg-risk-bg-red text-risk-red",
    'Very high': "bg-risk-bg-red text-risk-red",
};

export const PORTCOLORS: any = {
    "Low volatility": "bg-risk-bg-green text-risk-green",
    "Moderate volatility": "bg-risk-bg-yellow text-risk-yellow",
    "High volatility": "bg-risk-bg-orange text-risk-orange",
    "Very High volatility": "bg-risk-bg-red text-risk-red"
}


export const firms = [
    [
        "한국투자증권",
        "키움증권",
        "NH투자증권",
        "KB증권",
        "신한투자증권",
        "하나증권",
        "미래에셋증권",
        "카카오페이증권",
        "DB금융투자",
        "토스증권",
        "IBK투자증권",
        "SK증권",
        "상상인증권",
        "교보증권",
        "대신증권",
        "메리츠증권",
        "부국증권",
        "삼성증권",
        "신영증권",
        "유안타증권",
        "유진투자증권",
        "한화투자증권",
        "이베트스트증권",
        "한양증권",
        "유화증권",
        "없음",
    ],
    [
        "Korea Investment & Securities",
        "KIWOOM Securities",
        "NH Investment & Securities",
        "KB Securities",
        "Shinhan Securities",
        "Hana Financial Investment",
        "Mirae Asset Securities",
        "Kakao Pay Securities",
        "DB Financial Investment",
        "Toss investment",
        "IBK Securities",
        "SK Securities",
        "Sangsangin Investment & Securities",
        "Kyobo Securities",
        "Daishin Securities",
        "Meritz Securities",
        "Bookook Securities",
        "Samsung Securities",
        "Shinyoung Securities",
        "Yuanta Securities Korea",
        "Eugene Investment & Secs",
        "Hanwha investment&securities",
        "EBEST Investment & Securities",
        "Hanyang Securities",
        "Yuhwa Securities",
        "Nothing",
    ],
];