import Image from "next/image";
import { useRouter } from "next/router";

interface props {
  KR_DL: string;
  ENG_DL: string;
  ENG_NM: string;
}

const Weather = ({ KR_DL, ENG_DL, ENG_NM }: props) => {
  const router = useRouter();

  let changeKR = "";

  switch (KR_DL) {
    case "리스크 상승":
      changeKR = "상승";
      break;
    case "리스크 폭등":
      changeKR = "폭등";
      break;
    case "리스크 유지":
      changeKR = "유지";
      break;
    case "리스크 완화":
      changeKR = "완화";
      break;
    default:
      changeKR = "";
      break;
  }

  return (
    <div className="h-9 px-1 py-1.5 bg-white justify-start items-center gap-1 inline-flex">
      <Image
        src={`/images/weather/${ENG_NM}.svg`}
        width={24}
        height={24}
        alt={`weather`}
      />
      <p className="text-neutral-400 text-[15px] font-medium leading-normal">
        {router.locale === "ko" ? KR_DL && changeKR : ENG_DL && ENG_DL}
      </p>
    </div>
  );
};

export default Weather;
