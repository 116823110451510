import { NextRouter, useRouter } from 'next/router';
type option = {
    scroll?: boolean;
    shallow?: boolean;
}
type typeQuery = 'ai' | 'risk' | 'portfolio' | 'assetinfo';

export const toDetailPage = (
    router: NextRouter,
    ticker: string,
    type: typeQuery = 'ai',
    options?: option,
    method: 'push' | 'replace' = 'push',
) => {
    const url = `/detail/${ticker}?type=${type}`;

    if (method === 'replace') {
        router.replace(url, undefined, options);
    } else {
        router.push(url, undefined, options);
    }

};

//toDetailPage(router, data.ticker);
