import { COLORS, PORTCOLORS } from "data/default";
import { useRouter } from "next/router";
import { TYPO } from "styles/Typo";

const Level = ({ level }: { level: string }) => {
  const router = useRouter();
  return (
    <div
      className={`py-1 px-3 rounded-20 ${COLORS[level]} max-w-fit mx-auto shrink-0`}
    >
      <h6 className={`${TYPO.TYPO8_MD}`}>
        {router.locale === "ko"
          ? level === "Safe"
            ? "안전"
            : level === "Moderate"
            ? "적정"
            : level === "Caution"
            ? "주의"
            : level === "Danger"
            ? "위험"
            : ""
          : level}
      </h6>
    </div>
  );
};
export default Level;
