import axios from "axios";
import CustomToast from "components/atoms/CustomToast";
import { Grade } from "components/common/Icons";
import {
  useInterestToastDispatch,
  useInterestToastState,
} from "contexts/InterestContext";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useState } from "react";
import useInterest, { handleSDInterest } from "utils/clientAPIs/interest";
import * as Sentry from "@sentry/nextjs";

function InterestStar({ asset }: { asset: any }) {
  const router = useRouter();
  //관심자산
  // const [
  //   session,
  //   update,
  //   userInfo,
  //   mutate,
  //   interestedAssets,
  //   interestMutate,
  //   interestValid,
  // ] = useInterest();

  const { data: session, status, update }: any = useSession();
  const dispatch = useInterestToastDispatch();

  // useEffect(() => {
  //   if (userInfo && userInfo.user && userInfo.user.interest) {
  //     interestMutate();
  //   }
  // }, [userInfo, interestMutate]);

  // 관심자산에 이미 존재하는지 확인 / 초기화
  const existInterest = (item: any) => {
    if (session && session.user) {
      return session.user.interest.includes(item.ITEM_CD_DL);
    }
    return false;
  };

  const alarmRegister = async () => {
    await axios.post(session?.user?.email ? `/api/auth/alarm` : "", {
      email: session.user.email,
      ITEM_CD_DL: asset.ITEM_CD_DL,
    });
    update();
  };

  const alarmDelete = async () => {
    await axios.delete(
      session?.user?.email
        ? `/api/auth/alarm?email=${session.user.email}&ITEM_CD_DL=${asset.ITEM_CD_DL}`
        : ""
    );
    update();
  };

  const InterestClick = async ({ e, asset }: any) => {
    e.stopPropagation();
    if (!session) {
      router.push("/login");
      return;
    }
    const isInterested = existInterest(asset);
    try {
      const res = await handleSDInterest({
        session,
        update,
        detailData: asset,
        router,
        isInterested,
      });

      dispatch({
        type: "SET_INTEREST_TOAST_VISIBLE",
        payload: {
          toast: true,
          existInterest: !isInterested,
        },
      });

      // 관심 삭제시
      if (isInterested) {
        if (session?.user?.alarm.includes(asset.ITEM_CD_DL)) {
          await alarmDelete(); //알림 삭제
        }
        return;
      }

      ///---------

      //관심 등록시 -> 알림 등록

      // 이미 등록된 알람일 경우 아무것도 하지 않음
      if (
        session?.user?.alarm &&
        session?.user?.alarm.includes(asset.ITEM_CD_DL)
      ) {
        return;
      }

      const response: any = await axios
        .get(
          session?.user?.email
            ? `/api/auth/alarm?email=${session.user.email}`
            : ""
        )
        .then((res) => res.data);
      const isNotAgreeUser = response?.isNotAgreeUser === 0;

      //이미 등록해놓은 알림 종목이 1개이상 존재할 경우 -> 메서드를 등록한 유저
      if (session?.user?.alarm && session?.user?.alarm?.length > 0) {
        if (response?.isRiskAlarmOn === 0) {
          //유저가 알림을 OFF로 설정해놓았을 경우
          return;
        }
        //자동으로 알림 등록
        await alarmRegister();
        return;
      }

      //메서드는 등록해 놓았지만 알림종목이 0개인 상태에서 관심등록을 시도할 경우
      if (!isNotAgreeUser) {
        if (response?.isRiskAlarmOn === 0) {
          //유저가 알림을 끈 상태
          return;
        }
        //자동으로 알림 등록
        await alarmRegister();
        return;
      }

      //메서드를 등록하지 않았을 때 알림 등록 모달 노출
      const hideModal = localStorage.getItem("hideModal");
      if (hideModal && new Date(hideModal) > new Date()) {
        return;
      }
      if (isNotAgreeUser) {
        const timer = setTimeout(() => {
          const hideUntil = hideModal ? new Date(hideModal) : null;

          if (!hideUntil || hideUntil < new Date()) {
            dispatch({
              type: "SET_ALARM_MODAL_VISIBLE",
              payload: {
                alarmModal: true,
                ITEM_CD_DL: asset.ITEM_CD_DL,
              },
            });
          }
        }, 1000);

        return;
      }

      //
    } catch (error) {
      Sentry.captureException(error);
      dispatch({
        type: "SET_INTEREST_ERROR_TOAST_VISIBLE",
        payload: {
          toast: true,
          existInterest: isInterested,
        },
      });
    }
  };

  return (
    <div
      onClick={(e) => {
        InterestClick({ e, asset });
      }}
    >
      <Grade
        fill={existInterest(asset) ? "blue40" : "gray20"}
        height={36}
        width={36}
      />
    </div>
  );
}
export default InterestStar;
