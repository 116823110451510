import Image from "next/image";
import { useRouter } from "next/router";
import { TYPO } from "styles/Typo";

export interface priceDownProp {
  EXP_CVaRNTS_95: number;
  ITEM_CD_DL?: string;
}

const PriceDown = ({ EXP_CVaRNTS_95, ITEM_CD_DL }: priceDownProp) => {
  const router = useRouter();
  return (
    <div
      onClick={() => {
        if (ITEM_CD_DL) {
          router.push(`/detail/${ITEM_CD_DL}`);
        }
      }}
      className="h-10 p-2 bg-blue-50 rounded-md justify-end items-center gap-0.5 inline-flex"
    >
      <Image
        src={`/images/icons/priceDown.svg`}
        width={16}
        height={16}
        alt={`CVaR`}
      />
      <p className={`${TYPO.TYPO7_SB} text-blue50`}>
        {EXP_CVaRNTS_95 &&
          EXP_CVaRNTS_95.toLocaleString("en-us", {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          }) + "%"}
      </p>
    </div>
  );
};
export default PriceDown;
